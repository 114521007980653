"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModernViewContainer = void 0;
const React = require("react");
const CoreContext_1 = require("../../contexts/CoreContext");
const JoinLauncherContext_1 = require("../../contexts/join-launcher/JoinLauncherContext");
const ButtonsFactory_1 = require("../../helpers/ButtonsFactory");
const DownloadState_1 = require("../../models/DownloadState");
const JoinInfo_1 = require("../../models/JoinInfo");
const TelemetryEvents_1 = require("../../models/telemetry/TelemetryEvents");
const DownloadService_1 = require("../../services/DownloadService");
const Logger_1 = require("../../services/Logger");
const Footer_1 = require("../common/Footer");
const Ufd_1 = require("../common/Ufd");
const clockArrowDownloadImg = require("../../images/v3/clock_arrow_download.svg");
const warningImg = require("../../images/v3/warning.svg");
require("./ModernViewContainer.scss");
const ModernViewRenderer_1 = require("./ModernViewRenderer");
const RootView_1 = require("./RootView");
const Utils_1 = require("../../helpers/Utils");
const lastTimeUserEngagement = "lastTimeUserEngagement";
class ModernViewContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isBranded: false,
            showUfdOnJoinInAppClick: false,
        };
    }
    render() {
        return (React.createElement(CoreContext_1.CoreContextConsumer, null, ({ environment, launcherService, brandingService, featureFlags }) => (React.createElement(JoinLauncherContext_1.JoinLauncherContextConsumer, null, ({ joinInfo, downloadState }) => {
            const showDesktopButtons = !(joinInfo.type === JoinInfo_1.JoinType.MeetupWithCode && featureFlags.disableTfLDesktop) &&
                !(joinInfo.type === JoinInfo_1.JoinType.TeamsInsider &&
                    !environment.isWindows11 &&
                    !featureFlags.enableTflInsiderProgramNonWin11) &&
                !(joinInfo.type === JoinInfo_1.JoinType.SchoolConnection);
            const buttonsFactory = this.getButtonFactory(environment, featureFlags, launcherService, joinInfo, downloadState, showDesktopButtons);
            brandingService.isBrandingApplied().then(isBranded => {
                if (isBranded !== this.state.isBranded) {
                    this.setState({ isBranded });
                }
            });
            return (React.createElement("div", { className: this.props.computedFlags.showHeaderContainerShimmer
                    ? "modernViewContainer"
                    : "modernViewContainer noShimmer" },
                React.createElement("div", { id: "container" },
                    React.createElement(ModernViewRenderer_1.ModernViewRenderer, { buttonsFactory: buttonsFactory, showContinueInBrowser: this.shouldShowContinueInBrowser(environment), launchResult: this.props.launchResult, launchMethod: launcherService.getLaunchMethod(), type: this.props.type })),
                React.createElement(Footer_1.Footer, { className: "disclaimer", onLinkClick: () => Logger_1.default.logUserBiPanelAction(new TelemetryEvents_1.PanelActionEvent(TelemetryEvents_1.PanelActionScenarioNames.ModernView, TelemetryEvents_1.ModuleNames.ShowThirdPartyNotice, joinInfo)), showTeamsLogo: true, showBrandingDisclaimer: this.state.isBranded }),
                this.shouldShowDownloadUfd(downloadState) ? (React.createElement(Ufd_1.Ufd, { browser: environment.browserName, text: "txt_during_download_v2", renderText: this.shouldRenderInstallPromptText(), img: clockArrowDownloadImg })) : null,
                this.state.showUfdOnJoinInAppClick ? (React.createElement(Ufd_1.Ufd, { browser: environment.browserName, title: "btn_continue_on_browser_title", text: "txt_coachmark_open_teams_guidance", renderText: true, img: warningImg })) : null));
        }))));
    }
    getButtonFactory(environment, featureFlags, launcherService, joinInfo, downloadState, showDesktopButtons) {
        return new ButtonsFactory_1.ButtonsFactory({
            meeting: this.props.type === RootView_1.ModernViewType.Meeting,
            showDesktopButtons,
            hideDownloadButton: featureFlags.hideDownloadButton ||
                (environment.isTflEnvironment &&
                    featureFlags.hideDownloadButtonOnTfl &&
                    environment.isWindows10),
            hideContinueButton: environment.isTflEnvironment &&
                // No web support for TeamsInsider (on T1) unless enabled via FF after T2 Web rollout
                ((joinInfo.type === JoinInfo_1.JoinType.TeamsInsider &&
                    !featureFlags.enableTflInsiderProgramContinueOnWeb) ||
                    joinInfo.type === JoinInfo_1.JoinType.SchoolConnection),
            isWindowsTfwUI: !environment.isTflEnvironment && featureFlags.enableWindowsTfwUI,
            isEdgeAnaheim: environment.isEdgeAnaheim,
            isWindows10: environment.isWindows10,
            showDesktopJoinInAppAsPrimary: environment.isTflEnvironment &&
                environment.isWindows11 &&
                featureFlags.enableTflWin11JoinInAppPrimaryAction,
        }, this.getButtonHandlers(environment, featureFlags, launcherService, joinInfo, downloadState));
    }
    shouldShowDownloadUfd(downloadState) {
        return ((downloadState.step === DownloadState_1.DownloadStep.InProgress ||
            downloadState.step === DownloadState_1.DownloadStep.Intermediate) &&
            !this.state.showUfdOnJoinInAppClick);
    }
    shouldRenderInstallPromptText() {
        return (this.props.type !== RootView_1.ModernViewType.FreemiumOnboarding &&
            this.props.type !== RootView_1.ModernViewType.ShareEmail);
    }
    shouldShowContinueInBrowser(environment) {
        switch (this.props.type) {
            case RootView_1.ModernViewType.Meeting:
                return environment.isWebClientSupportedBrowser;
            // TODO: check ShareEmail scenario for browsers
            case RootView_1.ModernViewType.ShareEmail:
                return true;
            default:
                return false;
        }
    }
    // Task: https://domoreexp.visualstudio.com/MSTeams/_workitems/edit/2097067
    markVisitors(enableVisitorMarking, isTflDomain) {
        const notVisited = !window.localStorage.getItem(lastTimeUserEngagement);
        if (enableVisitorMarking && notVisited && !isTflDomain) {
            window.localStorage.setItem(lastTimeUserEngagement, Date.now().toString());
        }
    }
    getButtonHandlers(environment, featureFlags, launcherService, joinInfo, downloadState) {
        const downloadBehavior = DownloadService_1.default.getDownloadBehaviour(joinInfo, environment, featureFlags, launcherService, downloadState);
        const handlers = {
            download: () => {
                this.hideJoinInAppClickUfdIfVisible();
                const panelActionEvent = new TelemetryEvents_1.PanelActionEvent(TelemetryEvents_1.PanelActionScenarioNames.ModernView, TelemetryEvents_1.ModuleNames.Download, joinInfo);
                Logger_1.default.logUserBiPanelAction(panelActionEvent);
                if (downloadBehavior) {
                    this.markVisitors(featureFlags.enableVisitorMarking, environment.isTflEnvironment);
                    downloadBehavior.onClick();
                }
            },
            redirectToDownloadPage: () => {
                const panelActionEvent = new TelemetryEvents_1.PanelActionEvent(TelemetryEvents_1.PanelActionScenarioNames.ModernView, TelemetryEvents_1.ModuleNames.Download, joinInfo);
                Logger_1.default.logUserBiPanelAction(panelActionEvent);
                this.markVisitors(featureFlags.enableVisitorMarking, environment.isTflEnvironment);
                launcherService.redirectToDownloadPage();
            },
            joinOnWeb: () => {
                const panelActionEvent = new TelemetryEvents_1.PanelActionEvent(TelemetryEvents_1.PanelActionScenarioNames.ModernView, TelemetryEvents_1.ModuleNames.JoinOnWeb, joinInfo);
                Logger_1.default.logUserBiPanelAction(panelActionEvent);
                this.markVisitors(featureFlags.enableVisitorMarking, environment.isTflEnvironment);
                launcherService.redirectToWebJoin();
            },
            joinInApp: () => {
                const panelActionEvent = new TelemetryEvents_1.PanelActionEvent(TelemetryEvents_1.PanelActionScenarioNames.ModernView, TelemetryEvents_1.ModuleNames.JoinInApp, joinInfo);
                Logger_1.default.logUserBiPanelAction(panelActionEvent);
                this.markVisitors(featureFlags.enableVisitorMarking, environment.isTflEnvironment);
                this.props.onLaunchClick();
                Utils_1.Utils.protocolDetectionSequence().then(({ isDetected }) => {
                    !isDetected && this.showJoinInAppClickUfd();
                });
            },
        };
        return handlers;
    }
    hideJoinInAppClickUfdIfVisible() {
        // Clear the timeout of the JoinInApp UFD
        window.clearTimeout(this.ufdJoinInAppClickTimeoutId);
        // Hide the JoinInApp UFD if it is visible
        this.state.showUfdOnJoinInAppClick && this.setState({ showUfdOnJoinInAppClick: false });
    }
    showJoinInAppClickUfd() {
        // Clear the timeout of the JoinInApp UFD. Required for the edge case where the JoinInApp button is clicked multiple times
        window.clearTimeout(this.ufdJoinInAppClickTimeoutId);
        // Show the JoinInApp UFD
        this.setState({ showUfdOnJoinInAppClick: true });
        // Hide the JoinInApp UFD after a defined timeout
        this.ufdJoinInAppClickTimeoutId = Utils_1.Utils.genericTimeout(() => this.setState({ showUfdOnJoinInAppClick: false }), 8000);
    }
}
exports.ModernViewContainer = ModernViewContainer;
