"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const BaseConfig_1 = require("../BaseConfig");
const Environment_1 = require("../../models/Environment");
const gcchConfig = {
    env: Environment_1.BuildEnvironment.Gcchigh,
    ariaCollectorUri: "https://tb.pipe.aria.microsoft.com/Collector/3.0",
    OneDsCollectorUri: "https://tb.events.data.microsoft.com/OneCollector/1.0/",
    publicPath: "https://statics.gov.teams.microsoft.us",
    telemetry: {
        tenantToken: "78a006ddeb844bd0b504d81954c3391e-f4160d3d-00b0-477a-9766-e712fa138395-7027",
    },
    urls: Object.assign(Object.assign({}, BaseConfig_1.default.urls), { absoluteUrls: Object.assign(Object.assign({}, BaseConfig_1.default.urls.absoluteUrls), { brandingService: "https://middletier.gov.teams.microsoft.us/beta/meetings/branding" }) }),
    ecs: Object.assign(Object.assign({}, BaseConfig_1.default.ecs), { host: "https://config.ecs.gov.teams.microsoft.us" }),
};
exports.default = gcchConfig;
